//@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'micromodal';

body.not-front.etude-cas #main .page-liste-cas .infos .list.messageries {
  display: block;
}

.resource {

    &__icon,
    &__name {
        display: inline-block;
        vertical-align: middle;
    }

    &__icon {
        background: transparent none center center no-repeat;
        background-size: 100% 100%;

        width: 13px;
        height: 15px;

        @apply tw-mr-1;
    }

    &__name {
        color: #006ece;
        font-weight: 700;
    }

    &.-type-1 &__icon {
        background-image: url(../images/resources/icon-pdf.png);
    }
    &.-type-2 &__icon {
        background-image: url(../images/resources/icon-ppt.png);
    }
    &.-type-3 &__icon {
        background-image: url(../images/resources/icon-video.png);
    }
    &.-type-4 &__icon {
        background-image: url(../images/resources/icon-link.png);
    }
}

.resources-list {
    display: inline-block;
    vertical-align: top;
    @apply tw-space-y-1;
}


.btn-share {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
}

.modal-so{
    .modal-dialog {
        text-align: center;
        position: relative;
        top: 30%;
        .modal-content {
            .modal-header {
                h2 {
                    font-size: 20px;
                }
            }
            .modal-body {
                text-align: left;
                h5 {
                    font-size: 14px;
                }
                #add_warning {
                    color: red;
                }
            }
        }
    }
}

h2 {
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0.025em;
    margin: 20px 40px;
    position: relative;
    text-transform: uppercase;
    color: #006ECE;
}
.descriptif {
    margin: 0 0 30px;
}

.descriptif-maj {
    font-size: 1.2em;
    padding: 0 40px;
    letter-spacing: 0.05em;
    margin-bottom: -10px;
}

.descriptif-container {
    background-color: #fff;
}

.shareContainer {
    display: flex;
    .fb-share-button, .linkedIn-share-btn {
        margin: 5px;
    }
}

.paginationControl {
    @apply tw-flex tw-justify-center tw-mt-14;
}

@media (min-width: 200px) and (max-width: 767px){
    .tw-m-center{
        text-align: center
    }
}
#cgucondition {
    .modal-dialog {
        width: 740px;
        max-width: 740px;
        top: 10%;
        .modal-body {
            overflow-y: scroll;
            height: 450px;
        }
        .modal-footer {
            padding: 5px 25px;
            .disclaimer {
                margin-bottom: 0px;
                text-align: center;
            }
            .not-down {
                color: red;
            }
            .down {
                color: #4a5073;
            }
        }
    }
}
.examen-button-container{
    &.examen-previous {
        margin-bottom: 5px;
    }
}
